import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ExamMentorService {
  constructor(
    private api: ApiService
  ) {
  }

  getMentorByRanking(qs = '') {
    return this.api.get(`/client/exam-mentors/by-ranking${qs}`);
  }

  getToMyGoal(qs = '') {
    return this.api.get(`/client/exam-mentors/to-my-goal${qs}`);
  }

  getToUp(qs = '') {
    return this.api.get(`/client/exam-mentors/to-up${qs}`);
  }

  getWithId(id: number) {
    return this.api.get(`/client/exam-mentors/${id}`);
  }
}
